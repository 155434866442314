module.exports = {
  socialIcon: [
    {
      title: "Instagram",
      icon: require("@/assets/images/networks/instagram.svg"),
      url: "https://instagram.com/JohnnyGrid",
    },
    {
      title: "TikTok",
      icon: require("@/assets/images/networks/tiktok.svg"),
      url: "https://www.tiktok.com/@JohnnyGrid",
    },
    {
      title: "YouTube",
      icon: require("@/assets/images/networks/youtube.svg"),
      url: "https://www.youtube.com/channel/UCF47UOKvbpLwjOjSaQgMJcg?sub_confirmation=1",
    },
    {
      title: "LinkedIn",
      icon: require("@/assets/images/networks/linkedin.svg"),
      url: "https://www.linkedin.com/in/JohnnyGrid",
    },
    {
      title: "Medium",
      icon: require("@/assets/images/networks/medium.svg"),
      url: "https://medium.com/@JohnnyGrid",
    },
  ],
  button: [
    {
      title: "Web Jedi ⋅ Telegram Channel 💥",
      url: "https://t.me/JohnnyGrid",
    },
    {
      title: "The Force ⋅ My online course 🚀 (coming soon)",
      url: "https://JohnnyGrid.com",
    },
    {
      title: "Contact Me ✉️",
      url: "mailto:hello@johnnygrid.com",
    },
    // {
    //   title: "Patreon",
    //   url: "https://patreon.com/JohnnyGrid",
    // },
  ],
};

/* List of all links:
https://instagram.com/JohnnyGrid
https://www.tiktok.com/@JohnnyGrid
https://twitter.com/JohnnyGrid
https://t.me/JohnnyGrid
https://www.linkedin.com/in/JohnnyGrid
https://gitlab.com/JohnnyGrid
https://patreon.com/JohnnyGrid
https://medium.com/@JohnnyGrid
https://JohnnyGrid.com

https://www.facebook.com/itsJohnnyGrid
https://github.com/itsJohnnyGrid
*/
