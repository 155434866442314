<template>
  <span class="svg-icon">
    <inline-svg :src="src" />
  </span>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  components: {
    InlineSvg,
  },

  props: {
    src: {
      type: String,
      required: true,
      default: "",
    },
  },
};
</script>

<style lang="postcss" scoped>
.svg-icon {
  svg:deep(path) {
    @apply fill-current text-gray-300;
  }

  &:hover {
    svg:deep(path) {
      @apply fill-current text-white;
    }
  }
}
</style>
