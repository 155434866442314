<template>
  <div class="main">
    <div class="main-block">
      <img class="main-block-elips-bg-right" :src="elipsBackground.right" />
      <img class="main-block-elips-bg-left" :src="elipsBackground.left" />

      <div class="main-block-padding">
        <img alt="userIcon" :src="userIcon" class="main-card-avatar" />

        <Card class="main-card">
          <div class="main-card-user">@JohnnyGrid</div>

          <div class="main-card-title">Web Jedi ⋅ Frontend Developer</div>

          <div class="main-card-description">
            ✨ Teaching how to boost your career as an inspired web developer.
          </div>

          <div class="main-card-networks">
            <a
              v-for="(item, index) in socialIconLinks"
              :key="index"
              :href="item.url"
              target="_blank"
            >
              <SvgIcon class="main-card-networks-icon" :src="item.icon" />
            </a>
          </div>

          <a
            v-for="(item, index) in buttonLinks"
            :key="index"
            :href="item.url"
            class="link-button"
            target="_blank"
          >
            {{ item.title }}
          </a>
        </Card>
      </div>

      <div v-if="false" v-dragscroll class="description">
        <Card v-for="(item, index) in products" :key="index" class="description-card">
          <div class="description-icons">
            <img class="description-icons-main" :src="item.mainIcon" alt="description-icon" />

            <img class="description-icons-support" :src="item.subIcon" alt="description-icon" />
          </div>

          <div class="description-title">
            {{ item.title }}
          </div>

          <div class="description-info">
            {{ item.description }}
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "./components/Card";
import { dragscroll } from "vue-dragscroll";
import SvgIcon from "@/views/home/components/SvgIcon";
import { button, socialIcon } from "@/configs/links.config";
import { products } from "@/configs/products.config";

export default {
  name: "Home",

  directives: {
    dragscroll,
  },

  components: {
    SvgIcon,
    Card,
  },

  data: () => ({
    socialIconLinks: socialIcon,
    buttonLinks: button,
    products: products,
  }),

  computed: {
    userIcon() {
      return require("@/assets/images/avatar.jpg");
    },

    elipsBackground() {
      return {
        left: require("@/assets/images/leftElipsBg.svg"),
        right: require("@/assets/images/rightElipsBg.svg"),
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.main {
  @apply bg-accent;
  @apply min-h-screen;

  &-card {
    @apply space-y-4 pt-8;

    &-avatar {
      @apply relative rounded-full shadow;
      @apply z-10 mx-auto -mb-5 mt-5;
      @apply h-[6.25rem] w-[6.25rem];
    }

    &-user {
      @apply text-center text-base font-normal text-gray-300;
      @apply !mt-1;
    }

    &-title {
      @apply text-center text-2xl font-bold text-white;
    }

    &-description {
      @apply text-center text-base font-normal text-gray-300;
    }

    &-networks {
      @apply flex items-center justify-center;
      @apply space-x-8 py-2;

      &-icon {
        @apply cursor-pointer;
      }
    }
  }

  &-block {
    @apply relative;
    @apply flex flex-col;
    @apply min-h-screen w-full max-w-[40rem];
    @apply mx-auto pb-4;

    &-padding {
      @apply px-4;
    }

    &-elips-bg {
      &-right {
        @apply absolute top-8 right-4;
      }

      &-left {
        @apply absolute bottom-[6.25rem] left-4;
      }
    }
  }
}

.link-button {
  @apply block;
  @apply text-center text-base font-medium text-white;
  @apply border border-white;
  @apply rounded-lg px-1.5 py-4;
  @apply w-full cursor-pointer;

  &:hover {
    @apply bg-white bg-opacity-5;
    @apply border-blue-300;
    @apply text-blue-300;
  }

  &:active {
    @apply bg-white bg-opacity-10;
    @apply border-blue-500;
    @apply text-blue-500;
  }
}

.description {
  @apply flex;
  @apply mt-6 space-x-4 px-4;
  @apply cursor-pointer overflow-x-scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &-card {
    @apply w-[14.9375rem];
    @apply flex-none;
  }

  &-icons {
    @apply flex items-end;
    @apply mb-4 space-x-0.5;

    &-main {
      @apply h-[2.375rem] w-[2.375rem];
    }

    &-support {
      @apply h-[1.3125rem] w-[1.3125rem];
    }
  }

  &-title {
    @apply text-base font-medium text-white;
    @apply mb-0.5;
  }

  &-info {
    @apply text-base font-normal text-gray-300;
  }
}
</style>
