module.exports = {
  products: [
    {
      mainIcon: require("@/assets/images/Technologist.png"),
      subIcon: require("@/assets/images/Money.png"),
      title: "Online course",
      description: "Teaching how to land your dream job",
    },
    {
      mainIcon: require("@/assets/images/Technologist.png"),
      subIcon: require("@/assets/images/Money.png"),
      title: "Online course",
      description: "Teaching how to land your dream job",
    },
    {
      mainIcon: require("@/assets/images/Technologist.png"),
      subIcon: require("@/assets/images/Money.png"),
      title: "Online course",
      description: "Teaching how to land your dream job",
    },
    {
      mainIcon: require("@/assets/images/Technologist.png"),
      subIcon: require("@/assets/images/Money.png"),
      title: "Online course",
      description: "Teaching how to land your dream job",
    },
  ],
};
